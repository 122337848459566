import React from "react";
import "./App.css";
import { MainCard } from "./components/MainCard";

function App() {
  return (
    <div className="App">
      <body className="App-body">
        <MainCard />
      </body>
    </div>
  );
}

export default App;
