import { Timeline } from "./TimeLine";
import React, { FC } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { grey } from "@material-ui/core/colors";
import { LinkButtonMenu } from "./LinkButtonMenu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 700,
      minWidth: 300,
      margin: 15,
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    title: {
      fontSize: 25,
      textAlign: "left",
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    avatar: {
      backgroundColor: grey[800],
      fontSize: 12,
    },
  })
);

export const MainCard: FC = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.title}
        avatar={
          <Avatar aria-label="Paul image" className={classes.avatar}>
            PW
          </Avatar>
        }
        // action={
        //   <IconButton aria-label="settings">
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        title="Paul White"
        subheader="Test Engineer"
      />
      {/* <CardMedia
        className={classes.media}
        // image="/static/images/cards/paella.jpg"
        title="About me"
      /> */}
      <CardContent>
        <Typography
          variant="body2"
          style={{
            color: grey[800],
          }}
          component="p"
        >
          A competent and enthusiastic Test Engineer with a heuristic technical
          approach to testing.
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <LinkButtonMenu />
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography
            variant="body2"
            style={{
              color: grey[800],
            }}
          >
            <Timeline />
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};
