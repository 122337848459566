import React, { FC } from "react";
import LinkIcon from "@material-ui/icons/Link";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";
import LocalDrinkIcon from "@material-ui/icons/LocalDrink";
import PaymentIcon from "@material-ui/icons/Payment";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { IconButton } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

export const LinkButtonMenu: FC = () => {
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <IconButton aria-label="link" {...bindTrigger(popupState)}>
            <LinkIcon />
          </IconButton>
          <Menu {...bindMenu(popupState)}>
            <MenuItem
              style={{
                display: "flex",
                alignItems: "center",
                color: grey[800],
              }}
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/in/paul-white-1239b987/",
                  "_blank"
                )
              }
            >
              <LinkedInIcon style={{ paddingRight: 6 }} /> LinkedIn
            </MenuItem>
            <MenuItem
              style={{
                display: "flex",
                alignItems: "center",
                color: grey[800],
              }}
              onClick={() =>
                window.open("https://github.com/paulmwhite", "_blank")
              }
            >
              <GitHubIcon style={{ paddingRight: 6 }} />
              GitHub
            </MenuItem>
            <MenuItem
              style={{
                display: "flex",
                alignItems: "center",
                color: grey[800],
              }}
              onClick={() =>
                window.open("https://untappd.com/user/paulmwhite", "_blank")
              }
            >
              <LocalDrinkIcon style={{ paddingRight: 6 }} />
              Untappd
            </MenuItem>
            <MenuItem
              style={{
                display: "flex",
                alignItems: "center",
                color: grey[800],
              }}
              onClick={() =>
                window.open("https://monzo.me/paulwhite6", "_blank")
              }
            >
              <PaymentIcon
                style={{
                  paddingRight: 6,
                }}
              />
              Monzo.me
            </MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
};
