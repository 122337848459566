import React, { FC } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import { IconSensyne, IconDominos } from "../images/illustrations";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import MoneyIcon from "@material-ui/icons/Money";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    timeline: {
      "&::before": {
        background: grey[800],
      },
    },
  })
);

export const Timeline: FC = () => {
  const classes = useStyles();

  return (
    <VerticalTimeline className={classes.timeline} layout={"1-column"}>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: grey[800], color: "#fff", padding: 3 }}
        contentArrowStyle={{ borderRight: "7px solid  rgb(66, 66, 66)" }}
        date=""
        iconStyle={{
          background: grey[800],
          color: "#fff",
        }}
        icon={<IconSensyne />}
      >
        <h3 className="vertical-timeline-element-title">Senior QA Engineer</h3>
        <h4 className="vertical-timeline-element-subtitle">Sensyne Health</h4>
        <p>Jun 2020 - present</p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: grey[800], color: "#fff", padding: 3 }}
        contentArrowStyle={{ borderRight: "7px solid  rgb(66, 66, 66)" }}
        date=""
        iconStyle={{ background: grey[800], color: "#fff" }}
        icon={<IconSensyne />}
      >
        <h3 className="vertical-timeline-element-title">QA Engineer</h3>
        <h4 className="vertical-timeline-element-subtitle">Sensyne Health</h4>
        <p>Sep 2019 - Jun 2020</p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: grey[800], color: "#fff", padding: 3 }}
        contentArrowStyle={{ borderRight: "7px solid  rgb(66, 66, 66)" }}
        date=""
        iconStyle={{ background: grey[800], color: "#fff" }}
        icon={<IconDominos />}
      >
        <h3 className="vertical-timeline-element-title">Test Engineer</h3>
        <h4 className="vertical-timeline-element-subtitle">
          Domino's Pizza UK & Ireland
        </h4>
        <p>Nov 2017 – Sep 2019</p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: grey[800], color: "#fff", padding: 3 }}
        contentArrowStyle={{ borderRight: "7px solid  rgb(66, 66, 66)" }}
        date=""
        iconStyle={{ background: grey[800], color: "#fff" }}
        icon={<IconDominos />}
      >
        <h3 className="vertical-timeline-element-title">Software Tester</h3>
        <h4 className="vertical-timeline-element-subtitle">
          Domino's Pizza UK & Ireland
        </h4>
        <p>Aug 2016 – Nov 2017</p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: grey[800], color: "#fff", padding: 3 }}
        contentArrowStyle={{ borderRight: "7px solid  rgb(66, 66, 66)" }}
        date=""
        iconStyle={{ background: grey[800], color: "#fff" }}
        icon={<MonetizationOnIcon />}
      >
        <h3 className="vertical-timeline-element-title">
          Senior Test Engineer
        </h3>
        <h4 className="vertical-timeline-element-subtitle">DFC Global Corp</h4>
        <p>Jul 2014 – Aug 2016</p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: grey[800], color: "#fff", padding: 3 }}
        contentArrowStyle={{ borderRight: "7px solid  rgb(66, 66, 66)" }}
        date=""
        iconStyle={{ background: grey[800], color: "#fff" }}
        icon={<MoneyIcon />}
      >
        <h3 className="vertical-timeline-element-title">
          Software Test Analyst
        </h3>
        <h4 className="vertical-timeline-element-subtitle">
          MEM Consumer Finance
        </h4>
        <p>Jul 2013 – Aug 2014</p>
      </VerticalTimelineElement>
    </VerticalTimeline>
  );
};
